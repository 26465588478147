/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import {
	GET_ONBOARDING_FLOW,
	GET_ONBOARDING_STEP,
	COMPLETE_ONBOARDING_STEP, UPDATE_ONBOARDING_PROFILE,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Onboarding {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getFlow(flowCode) {
		const { method, endpoint } = GET_ONBOARDING_FLOW;
		return this.data[method](endpoint(flowCode)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getStep(flowCode, stepCode) {
		const { method, endpoint } = GET_ONBOARDING_STEP;
		return this.data[method](endpoint(flowCode, stepCode)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateOnboardingFields(payload, stepCode) {
		const { method, endpoint } = UPDATE_ONBOARDING_PROFILE;
		return this.data[method](endpoint(stepCode), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	completeStep(flowCode, stepCode, payload) {
		const { method, endpoint } = COMPLETE_ONBOARDING_STEP;
		return this.data[method](endpoint(flowCode, stepCode), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Onboarding;
